export const centerItemInNav = (element) => {
  if (element) {
    const nav = document.querySelector('nav');
    if (nav) {
      const center = Math.ceil(nav.offsetWidth / 2);
      const { offsetLeft, offsetWidth } = element;
      const left = offsetLeft - (center - Math.ceil(offsetWidth / 2));
      nav.scrollTo({ behavior: 'smooth', left });
    }
  }
};

export const getFlatNavigation = ({ navigation }) => {
  // Create a map of shortName to navigation objects for quick lookups

  const navMap = new Map();
  navigation?.forEach((nav) => {
    navMap.set(nav.shortName, nav);
    nav?.subNavigations?.forEach((subNav) => {
      navMap.set(subNav.shortName, subNav);
      subNav?.subSubNavigations?.forEach((subSubNav) => {
        navMap.set(subSubNav.shortName, subSubNav);
      });
    });
  });

  return navMap;
};

export const getPaginationData = ({ navigation, url, pageSize = 10 }) => {
  const navMap = getFlatNavigation({ navigation });

  return {
    count: navMap.get(url.split('/').pop().split('?')[0])?.count,
    lastPage: Math.ceil(
      navMap.get(url.split('/').pop().split('?')[0])?.count / pageSize,
    ),
    text: navMap.get(url.split('/').pop().split('?')[0])?.text || null,
    title: navMap.get(url.split('/').pop().split('?')[0])?.linkText || null,
  };
};

export const getURLs = ({ currentPage, lastPage, url }) => {
  const cleanUrl = url.split('/').pop().split('?')[0];
  const previousPage =
    currentPage === 2 ? 0 : currentPage === 0 ? null : currentPage - 1;
  const nextPage =
    currentPage === lastPage ? null : currentPage === 0 ? 2 : currentPage + 1;
  const previousUrl =
    currentPage === 2
      ? cleanUrl
      : currentPage === 0
      ? null
      : `${cleanUrl}?page=${currentPage - 1}`;
  const nextUrl =
    currentPage === lastPage
      ? null
      : currentPage === 0
      ? `${cleanUrl}?page=2`
      : `${cleanUrl}?page=${currentPage + 1}`;

  return { cleanUrl, nextPage, nextUrl, previousPage, previousUrl };
};
